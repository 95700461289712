import React, { useState } from "react"
import "./faq.css"

const FaqEntry = ({ q, a }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <li className="faq-item">
      <p className="faq-q" onClick={() => setExpanded(!expanded)}>
        <b>{q}</b>
      </p>
      {expanded ? <p className="faq-a">{a}</p> : null}
    </li>
  )
}

export default FaqEntry
