import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FaqEntry from "../components/faq-entry"

const ImprintPage = () => (
  <Layout>
    <SEO title="FAQ" />

    <h1>FAQ - Häufig gestellte Fragen</h1>

    <ul className="faq-list">
      <FaqEntry
        q="Wie verschiebe ich die Position der Schüler einer Klasse?"
        a={`Im Tab "Klassen & Schüler" zwei Mal die Klasse antippen, oder auf Details gehen, dann rechts oben auf Bearbeiten gehen.
Während die Schulstunde läuft, können Pult oder Schüler nicht verschoben werden.`}
      />
      <FaqEntry
        q="Wie kann ich eine Klasse löschen?"
        a="Wenn alle Schüler aus einer Klasse gelöscht sind, kann auch die Klasse selber gelöscht werden."
      />
      <FaqEntry
        q="Kann man auch Notensystem für XYZ einbauen?"
        a="Ja, dafür bitte die Notenstaffelung und Region an hilfe@lehrerbuch.app senden."
      />
      <FaqEntry
        q="Welche Features sind geplant?"
        a={`Einmal ein Datenexport zu CSV und PDF pro Klasse und pro Schüler,
langfristig auch Anbindungen an bestehende Schulsysteme, um einfacher an die Klassen- und Schülerlisten zu kommen. Weitere Ideen sind natürlich immer willkommen!`}
      />
      <FaqEntry
        q="Die Buttons für SMS- oder E-Mail-Benachrichtigung sind deaktiviert. Warum?"
        a={`SMS und Mail-Versand kann erst ausgelöst werden, wenn beim Schüler die Telefonnummer beziehungsweise die E-Mail-Adresse der Eltern eingetragen ist.`}
      />
      <FaqEntry
        q="Meine Frage wird in dieser dürftigen Liste nicht behandelt!"
        a={`Dann einmal eine Nachricht an hilfe@lehrerbuch.app schreiben.
Wir versuchen so schnell wie möglich zu antworten.`}
      />
    </ul>
  </Layout>
)

export default ImprintPage
